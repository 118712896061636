<template>
  <div class="sport-component" @click="select" :class="classes">
    <img class="image" :src="image" alt=""/>

    <span class="name"> {{ name }} </span>

    <ComingSoon v-if="comingsoon"/>
  </div>
</template>

<script>
import ComingSoon from '@/components/Badges/ComingSoon'
import newcompetition from '@/mixins/newcompetition'

export default {
  name: 'Sport',
  mixins: [
    newcompetition
  ],
  props: {
    sport: {
      type: Object,
      required: true
    }
  },
  components: {
    ComingSoon
  },
  computed: {
    classes () {
      const { comingsoon } = this
      return {
        comingsoon
      }
    },
    name () {
      return this.sport.name
    },
    image () {
      return this.sport.image
    },
    comingsoon () {
      return this.sport.status === 'Coming soon'
    }
  },
  methods: {
    select () {
      this.setSport(this.sport)

      this.$router.push({ name: 'newcompetition.date'})
    }
  }
}
</script>
