<template>
  <subview class="newcompetition-discepline">
    <view-header>
      <custom-header :router-back="goToPrevPage">
        <template v-slot:default>
          <Icon :size="24" type="arrow-left"/>

          {{ $t('pages.newcompetition.discepline.title') }}
        </template>
      </custom-header>
    </view-header>

    <view-content :style="{marginTop: '16px'}">
      <Sport v-for="sport in sports" :key="sport.id" :sport="sport"/>
    </view-content>
  </subview>
</template>

<script>

import { mapState } from 'vuex'
import Icon from '@/components/Icon'
import Sport from '@/components/NewCompetition/Sport'

export default {
  name: 'Discepline',
  components: {
    Icon,
    Sport
  },
  computed: {
    ...mapState({
      sports: ({ competition }) => competition.sports
    })
  },
  methods: {
    goToPrevPage() {
      this.$router.push({path: '/'})
    }
  }
}
</script>
